function createObject() {
  let example = {
    id: "LBST_1+1_Grundvorstellungen_Gruppe_V2",
    showGroupNames: true,
    checkAfterHowManyItems: 3,
    checkAfterExactItemCount: false,
    workOrder: `Welche der dargestellten Situationen passen zu den verschiedenen Grundvorstellungen der Addition? Ziehen Sie die Texte in die jeweilige Gruppe.`,
    possibleCategories: [
      {
        id: 1,
        name: "Hinzufügen",
      },
      {
        id: 2,
        name: "Zusammenfassen",
      },
      {
        id: 3,
        name: "Vergleichen",
      },
      {
        id: 4,
        name: "keinerlei Grundvorstellung",
      },
    ],
    elements: [
      {
        id: 8,
        image: require("@/assets/epe/Gruppen/Grundvorstellungen/08.png"),
        category: 3,
        flex: 3,
      },
      {
        id: 2,
        image: require("@/assets/epe/Gruppen/Grundvorstellungen/02.png"),
        category: 1,
        flex: 3,
      },
      {
        id: 6,
        image: require("@/assets/epe/Gruppen/Grundvorstellungen/06.png"),
        category: 2,
        flex: 3,
      },
      {
        id: 3,
        image: require("@/assets/epe/Gruppen/Grundvorstellungen/03.png"),
        category: 1,
        flex: 3,
      },
      {
        id: 7,
        image: require("@/assets/epe/Gruppen/Grundvorstellungen/07.png"),
        category: 3,
        flex: 3,
      },
      {
        id: 4,
        image: require("@/assets/epe/Gruppen/Grundvorstellungen/04.png"),
        category: 2,
        flex: 3,
      },
      {
        id: 12,
        image: require("@/assets/epe/Gruppen/Grundvorstellungen/12.png"),
        category: 4,
        flex: 3,
      },
      {
        id: 1,
        image: require("@/assets/epe/Gruppen/Grundvorstellungen/01.png"),
        category: 1,
        flex: 3,
      },
      {
        id: 5,
        image: require("@/assets/epe/Gruppen/Grundvorstellungen/05.png"),
        category: 2,
        flex: 3,
      },
      {
        id: 10,
        image: require("@/assets/epe/Gruppen/Grundvorstellungen/10.png"),
        category: 4,
        flex: 3,
      },
      {
        id: 11,
        image: require("@/assets/epe/Gruppen/Grundvorstellungen/11.png"),
        category: 4,
        flex: 3,
      },
      {
        id: 9,
        image: require("@/assets/epe/Gruppen/Grundvorstellungen/09.png"),
        category: 3,
        flex: 3,
      },
    ],
  };

  const zweiHinzufuegen = `Bei zwei der Aufgaben wird einer Menge von Objekten eine weitere Menge hinzugefügt, `;

  const zweiZusammenfassen = `Bei zwei der Aufgaben werden zwei Mengen zusammengefasst, `;

  const zweiVergleichen = `Zwei der Aufgaben stellen einen Vergleich zwischen zwei Mengen an, `;

  const zweiKeinerleiGV = `Zwei der Aufgaben legen keinerlei additive Strukturen nahe, `;

  const Hinzufuegen1 = `eine Aufgabe fügt jedoch einer Menge von Objekten eine weitere hinzu. Welche Aufgabe passt nicht? Versuchen Sie es erneut.`;

  const Zusammenfassen1 = `eine fasst jedoch zwei Mengen zusammen. Welche Aufgabe passt nicht? Versuchen Sie es erneut.`;

  const Vergleichen1 = `eine stellt Vergleiche zwischen zwei Mengen an, welche Aufgabe passt nicht? Versuchen Sie es erneut.`;

  const KeinerleiGV1 = `eine der Aufgaben legt allerdings keinerlei additive Struktur nahe. Welche der Aufgaben passt nicht? Versuchen Sie es erneut.`;

  example.responses = [
    {
      id: "0003",
      category: example.possibleCategories[3], // Array Index not id
      text:
        "Super. Bei diesen drei Aufgaben sind keine Grundvorstellungen der Addition zu erkennen.",
    },
    {
      id: "0012",
      text: zweiKeinerleiGV + Vergleichen1,
    },
    {
      id: "0021",
      text: zweiVergleichen + KeinerleiGV1,
    },
    {
      id: "0030",
      category: example.possibleCategories[2], // Array Index not id
      text:
        "Prima. Bei diesen drei Aufgaben ist die Grundvorstellung der Addition zu erkennen. Dabei werden zwei Mengen durch Addition verglichen, weshalb die Grundvorstellung Vergleichen genannt wird.",
    },
    {
      id: "0102",
      text: zweiKeinerleiGV + Zusammenfassen1,
    },
    {
      id: "0111",
      text:
        "Upps. Das ist noch sehr durcheinander. Welche Aufgaben zeigen Handlungen, bei denen zwei Mengen zusammengelegt werden? Gibt es Aufgaben, die Vergleiche anstellen oder welche, die nicht richtig in den Kontext einer Addition passen? Versuchen Sie es erneut.",
    },
    {
      id: "0120",
      text: zweiVergleichen + Zusammenfassen1,
    },
    {
      id: "0201",
      text: zweiZusammenfassen + KeinerleiGV1,
    },
    {
      id: "1101",
      text:
        "Upps. Das ist noch sehr durcheinander. Welche Aufgaben zeigen Handlungen, bei denen einer Menge von Objekten, eine weitere hinzugefügt wird? Welche Aufgaben Handlungen, bei denen zwei Mengen zusammengelegt werden? Gibt es Aufgaben die nicht richtig in den Kontext einer Addition passen? Versuchen Sie es erneut.",
    },
    {
      id: "1110",
      text:
        "Upps. Das ist noch sehr durcheinander. Welche Aufgaben zeigen Handlungen, bei denen einer Menge von Objekten, eine weitere hinzugefügt wird? Welche Aufgaben Handlungen, bei denen zwei Mengen zusammengelegt werden? Gibt es Aufgaben, die Vergleiche anstellen? Versuchen Sie es erneut.",
    },
    {
      id: "1200",
      text: zweiZusammenfassen + Hinzufuegen1,
    },
    {
      id: "2001",
      text: zweiHinzufuegen + KeinerleiGV1,
    },
    {
      id: "2010",
      text: zweiHinzufuegen + Zusammenfassen1,
    },
    {
      id: "2100",
      text: zweiHinzufuegen + Zusammenfassen1,
    },
    {
      id: "3000",
      category: example.possibleCategories[0], // Array Index not id
      text:
        "Klasse. Bei diesen drei Aufgaben wird einer Menge von Objekten eine weitere Menge hinzugefügt. Deshalb heißt diese Grundvorstellung Hinzufügen.",
    },
    {
      id: "0210",
      text: zweiZusammenfassen + Vergleichen1,
    },
    {
      id: "0300",
      category: example.possibleCategories[1], // Array Index not id
      text:
        "Sehr gut. Hier werden zwei Mengen zusammengelegt und die Gesamtzahl ermittelt. Diese Grundvorstellung heißt Zusammenfassen.",
    },
    {
      id: "1002",
      text: zweiKeinerleiGV + Hinzufuegen1,
    },
    {
      id: "1011",
      text:
        "Upps. Das ist noch sehr durcheinander. Welche Aufgaben zeigen Handlungen, bei denen einer Menge von Objekten, eine weitere hinzugefügt wird? Gibt es Aufgaben, die Vergleiche anstellen oder welche, die nicht richtig in den Kontext einer Addition passen? Versuchen Sie es erneut.",
    },
    {
      id: 1020,
      text: zweiVergleichen + Hinzufuegen1,
    },
  ];

  return example;
}

export default createObject();
