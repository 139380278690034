<template>
  <div>
    <h2 class="faledia-caption">Grundvorstellungen der Addition</h2>
    <v-card outlined>
      <v-card-title>Was sollen die Schüler:innen lernen?</v-card-title>
      <v-card-text>
        Die Kinder können...
        <ul>
          <li>
            zu gegebenen (Sach-)Situationen, die jeweils unterschiedliche
            Grundvorstellungen repräsentieren, passende Rechenaufgaben angeben.
          </li>
          <li>
            zu gegebenen Rechenaufgaben jeweils passende (Sach-)Situationen
            angeben, die unterschiedliche Grundvorstellungen repräsentieren.
          </li>
          <li>
            gegebene Sachsituationen, die unterschiedliche Grundvorstellungen
            repräsentieren, und gegebene Rechenoperationen (begründet) einander
            zuordnen.
          </li>
        </ul>
      </v-card-text>
    </v-card>
    <br />
    <p>
      Bereits vor der Thematisierung der Addition im Unterricht haben Kinder
      erste Vorstellungen davon, was für Aufforderungen mit dem Wort „plus“ oder
      dem Pluszeichen verknüpft sind. Manche wissen beispielsweise, dass etwas
      mehr wird, wenn etwas dazukommt oder wenn man etwas zusammenfügt. Solche
      Grundvorstellungen – d. h. mentale Bilder zur Addition, die das
      Pluszeichen mit Inhalt füllen – bilden eine wesentliche Voraussetzung für
      ein tragfähiges Verständnis der Addition. Diese Vorstellungen der Kinder
      gilt es daher aufzugreifen und zu festigen sowie zunehmend
      auszudifferenzieren (Selter & Zannetin, 2018).
    </p>
    <p>
      Weiterhin werden drei wesentliche Grundvorstellungen der Addition
      unterschieden. Versuchen Sie einmal zunächst die unterschiedlichen
      Kontexte nach denselben Vorstellungen zu gruppieren.
    </p>

    <div style="background-color: white">
      <LernbausteinGruppen
        :id="LBSTGruppe.id"
        :checkAfterHowManyItems="LBSTGruppe.checkAfterHowManyItems"
        :checkAfterExactItemCount="LBSTGruppe.checkAfterExactItemCount"
        :showGroupNames="LBSTGruppe.showGroupNames"
        :workOrder="LBSTGruppe.workOrder"
        :possibleCategories="LBSTGruppe.possibleCategories"
        :elements="LBSTGruppe.elements"
        :responses="LBSTGruppe.responses"
      >
        <template slot="alternative-content">
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr>
                  <td>Hinzufügen</td>
                  <td>
                    Einer Menge von Objekten wird eine weitere Menge
                    hinzugefügt. (dynamisch)
                  </td>
                  <td>
                    Paul hat 3 Bonbons. Er bekommt 4 Bonbons geschenkt. Wie
                    viele Bonbons hat er jetzt?
                  </td>
                </tr>
                <tr>
                  <td>Zusammenfassen</td>
                  <td>Zwei Mengen werden zusammengelegt. (statisch)</td>
                  <td>
                    Paul hat 3 Bonbons, Rebecca hat 5 Bonbons. Wie viele Bonbons
                    haben sie zusammen?
                  </td>
                </tr>
                <tr>
                  <td>Vergleichen</td>
                  <td>Zwei Mengen werden durch Addition verglichen.</td>
                  <td>
                    Paul hat 3 Bonbons, Sven hat 2 Bonbons mehr als Paul. Wie
                    viele Bonbons hat Sven?
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </template>
      </LernbausteinGruppen>
    </div>
    <p>
      Um die Entwicklung dieser Grundvorstellungen zu fördern, sind ikonische
      Darstellungen mit unterschiedlichen Rechengeschichten geeignet. Aber auch
      konkretes Handeln oder das Nutzen von Rechengeschichten, sind grundlegend für die
      Entwicklung von Grundvorstellungen zu fördern (Selter & Zannetin, 2018).
      Die Kinder lernen, wie die Geschichten durch Zahlensätze symbolisiert
      werden können und die unterschiedlichen Darstellungsebenen miteinander zu
      verknüpfen. Dies ist Grundlage für den sicheren Aufbau einer
      Grundvorstellung über die Operation der Addition (Wittmann et al., 2021).
      Die gesicherten Grundvorstellungen sind dabei deshalb so wichtig, da nur
      mit ihnen sinnvoll „gerechnet“ werden kann, weil erst dann verstanden
      wird, was konzeptuell dahintersteckt (Krauthausen, 2018).
    </p>
    <AppLiteraturEPE />
    <AppBottomNavEPE
      back="/einspluseins/hintergrundwissen"
      next="/einspluseins/hintergrundwissen/aufgabenbeziehungen"
    />
  </div>
</template>

<script>
import AppBottomNavEPE from "@/common/AppBottomNavEPE";
import AppLiteraturEPE from "@/common/AppLiteraturEPE";
import LernbausteinGruppen from "@/components/faledia/explorativeElemente/LernbausteinGruppen";
import Grundvorstellung_Gruppe from "@/components/faledia/seiten/einsPlusEins/LBST/LBST_1+1_Grundvorstellungen_Gruppe_V2";

export default {
  components: {
    AppBottomNavEPE,
    AppLiteraturEPE,
    LernbausteinGruppen,
  },
  data: () => ({
    LBSTGruppe: Grundvorstellung_Gruppe,
  }),
};
</script>

<style scoped></style>
